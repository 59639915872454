import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '../Icon';
import theme from '../../styles/theme';

const Wrapper = styled.div`
  margin: 2.5rem 0 0 0;
  display: inline-block;
  width: 100%;
  position: relative;
`;

const PageNumber = styled(Link)`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  line-height: 0;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 1.5rem;
  box-sizing: border-box;
  margin-right: 0.5rem;
  cursor: pointer;

  :hover {
    color: ${theme.hover.hoverBlue};
  }
`;

const Controls = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
`;

const Button = styled(Link)`
  padding: 1rem;
  border-radius: 2px;
  margin: 0 0 0 0.5rem;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s all;
  display: flex;

  &:hover {
    color: ${theme.hover.hoverBlue};

    svg path {
      fill: ${theme.icon.blueIcon};
    }
  }

  :first-child {
    margin: 0 0.5rem 0 0;

    svg {
      transform: rotate(180deg);
      margin: 0 0.5rem 0 0;
    }
  }
  svg {
    margin: 0 0 0 0.5rem;
  }
  @media(max-width: 991px) {
    padding: 0;
  }
`;

const cleanUrl = url => (
  !url.endsWith('/') ? url : url.substring(0, url.length - 1)
);

const Pagination = ({ context }) => {
  const {
    previousPagePath,
    numberOfPages,
    humanPageNumber,
    paginationPath,
    nextPagePath,
  } = context;
  const previousPagePathClean = cleanUrl(previousPagePath);
  const paginationPathClean = cleanUrl(paginationPath);
  const nextPagePathClean = cleanUrl(nextPagePath);

  return (
    <>
      {numberOfPages > 1 && (
        <Wrapper>
          <Controls>
            {/* first */}
            {previousPagePathClean && (
              <Button to={`${previousPagePathClean}/`}>
                <Icon name="arrow" />
                Prev
              </Button>
            )}
            {previousPagePathClean && (
              <PageNumber to={`${previousPagePathClean}/`}>
                {humanPageNumber - 1}
              </PageNumber>
            )}
            {/* second */}
            <PageNumber
              to={
                humanPageNumber !== 1
                  ? `${paginationPathClean}/${humanPageNumber}/`
                  : `${paginationPathClean}/`
              }
              style={{
                color: `${theme.hover.hoverBlue}`,
                border: `1.5px solid ${theme.borderColor.blueBorder}`,
              }}
            >
              {humanPageNumber}
            </PageNumber>
            {/* third */}
            {humanPageNumber !== numberOfPages && (
              <PageNumber to={`${nextPagePathClean}/`}>{humanPageNumber + 1}</PageNumber>
            )}
            {humanPageNumber + 1 >= numberOfPages ? (
              ''
            ) : (
              <>
                ...
                {/* last */}
                <PageNumber to={`${paginationPathClean}/${numberOfPages}/`}>
                  {numberOfPages}
                </PageNumber>
              </>
            )}
            {nextPagePathClean && (
              <Button to={`${nextPagePathClean}/`}>
                Next
                <Icon name="arrow" />
              </Button>
            )}
          </Controls>
        </Wrapper>
      )}
    </>
  );
};

Pagination.propTypes = {
  context: PropTypes.shape({
    previousPagePath: PropTypes.string.isRequired,
    nextPagePath: PropTypes.string.isRequired,
    numberOfPages: PropTypes.number.isRequired,
    paginationPath: PropTypes.string.isRequired,
    humanPageNumber: PropTypes.number.isRequired,
  }).isRequired,
};

export default Pagination;
