import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import theme from '../../styles/theme';

const Thumbnail = styled(GatsbyImage)`
  border-radius: 6px;
  width: 100%;
  height: auto;
  min-height: 15.3125rem;
  margin-bottom: 1.8125rem;

  @media (min-width: 655px) {
    margin-right: ${props => (props.reverse ? '0px' : '1rem')};
    margin-left: ${props => (props.reverse ? '1.187rem' : '1rem')};
    margin-bottom: 3.312rem;
    min-height: 16.652rem;
    flex: 60%;
  }

  @media (min-width: 1201px) {
    margin-right: ${props => (props.reverse ? '0px' : '3.187rem')};
    margin-left: ${props => (props.reverse ? '1.187rem' : '0.9rem')};
    min-height: 24.812rem;
    flex: 40%;
  }
`;

const Content = styled.div`
  align-items: center;

  h1 {
    font-size: 1.875rem;
    line-height: 2.15rem;
  }

  a:hover {
    color: ${theme.hover.hoverBlack};
  }

  @media (min-width: 655px) {
    display: flex;
    flex: 40%;
    justify-content: center;
  }
  @media (min-width: 1201px) {
    flex: 60%;

    h1 {
      font-size: 3.312rem;
      line-height: 4rem;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 655px) {
    justify-content: center;
    flex-direction: ${props => (props.reverse ? 'row' : 'row-reverse')};
  }
`;

export default function Hero({ featuredImage, children, reverse }) {
  return (
    <Wrapper reverse={reverse}>
      <Content>{children}</Content>
      <Thumbnail
        image={getImage(featuredImage)}
        alt={featuredImage.title}
        objectFit="cover"
        objectPosition="50% 50%"
        reverse={reverse}
      />
    </Wrapper>
  );
}

Hero.defaultProps = {
  featuredImage: {},
  reverse: false,
};

Hero.propTypes = {
  reverse: PropTypes.bool,
  featuredImage: PropTypes.shape({
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      base64: PropTypes.string,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
};
