import React from 'react';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Layout from '../components/layout';
import { Grid, GridItem } from '../components/Grid';
import PostCard from '../components/Blog/PostCard';
import Hero from '../components/Hero';
import Pagination from '../components/Blog/Pagination';
import SEO from '../components/seo';

const Header = styled.header`
  margin: 30px 0 0 0;

  h2 {
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-bottom: 3.625rem;
    display: inline-block;
  }
  h2 > span {
    font-weight: 300;
  }
  .pagination {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 300;
    float: right;
  }
`;

const BlogSection = styled.div`
  margin-bottom: 2.4375rem;

  a:hover h3 {
    color: #000000;
  }

  p {
    margin-bottom: 0px;
  }

  h2 {
    margin-bottom: 1.437rem;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
    line-height: 1.8125rem;
  }

  &:last-child {
    margin-bottom: 8.3125rem;
  }
`;

const CategoryIndexPage = ({ location, data, pageContext }) => {
  const posts = data.allContentfulBlogPost.edges;
  const {
    title,
    description,
    seoTitle,
    seoDescription
  } = data.contentfulCategory;
  const postHighlight = data.contentfulCategory.postHighlight
    && data.contentfulCategory.postHighlight[0];
  const { humanPageNumber } = pageContext;
  const seoMeta = {
    title: seoTitle || `${title} | Blog | Workast`,
    description: seoDescription ? seoDescription.seoDescription : description
  };
  return (
    <Layout location={location}>
      <SEO
        title={seoMeta.title}
        description={seoMeta.description}
        image={postHighlight ? postHighlight.featuredImage.gatsbyImageData : null}
      />
      <Header>
        <h2>
          {`${title} | `}
          <span>{`${description}`}</span>
        </h2>
        {humanPageNumber > 1 && (
          <span className="pagination">
            Page &nbsp;
            {humanPageNumber}
          </span>
        )}
      </Header>
      {postHighlight && humanPageNumber === 1 && (
        <Hero featuredImage={postHighlight.featuredImage}>
          <h1>
            <Link to={`/blog/${postHighlight.slug}/`}>{postHighlight.title}</Link>
          </h1>
        </Hero>
      )}
      <BlogSection>
        <Grid gridGap="4rem" minCol="290px">
          {posts.map(({ node }) => {
            const postTitle = node.title || node.slug;
            return (
              <GridItem key={node.slug}>
                <PostCard
                  title={postTitle}
                  slug={node.slug}
                  featuredImage={node.featuredImage}
                  excerpt={node.excerpt.excerpt}
                />
              </GridItem>
            );
          })}
        </Grid>
        <Pagination context={pageContext} />
      </BlogSection>
    </Layout>
  );
};

CategoryIndexPage.propTypes = {
  data: PropTypes.shape({
    allContentfulBlogPost: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            title: PropTypes.string,
            slug: PropTypes.string,
            publishDate: PropTypes.string,
            excerpt: PropTypes.shape({
              excerpt: PropTypes.string,
            }),
            featuredImage: PropTypes.shape({
              description: PropTypes.string,
              fluid: PropTypes.shape({
                aspectRatio: PropTypes.number,
                base64: PropTypes.string,
                sizes: PropTypes.string,
                src: PropTypes.string,
                srcSet: PropTypes.string,
                srcSetWebp: PropTypes.string,
                srcWebp: PropTypes.string,
              }),
            }),
          }),
        })
      ),
    }).isRequired,
    contentfulCategory: PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
      description: PropTypes.string,
      seoTitle: PropTypes.string,
      seoDescription: PropTypes.shape({
        seoDescription: PropTypes.string
      }),
      postHighlight: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          slug: PropTypes.string,
          featuredImage: PropTypes.shape({
            description: PropTypes.string,
            fluid: PropTypes.shape({
              aspectRatio: PropTypes.number,
              base64: PropTypes.string,
              sizes: PropTypes.string,
              src: PropTypes.string,
              srcSet: PropTypes.string,
              srcSetWebp: PropTypes.string,
              srcWebp: PropTypes.string,
            }),
            openGraph: PropTypes.shape({
              childImageSharp: PropTypes.shape({
                fixed: PropTypes.shape({
                  base64: PropTypes.string,
                  height: PropTypes.number,
                  width: PropTypes.number,
                  src: PropTypes.string,
                  srcSet: PropTypes.string,
                }),
              }),
            }),
          }),
        })
      ),
    }),
  }).isRequired,
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
  pageContext: PropTypes.shape({
    previousPagePath: PropTypes.string.isRequired,
    nextPagePath: PropTypes.string.isRequired,
    numberOfPages: PropTypes.number.isRequired,
    paginationPath: PropTypes.string.isRequired,
    humanPageNumber: PropTypes.number.isRequired,
    skip: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
  }).isRequired,
};

export default CategoryIndexPage;

export const pageQuery = graphql`
  query CategoryPostBySlug($slug: String!, $skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      filter: {
        category: { elemMatch: { slug: { eq: $slug } } }
        node_locale: { eq: "en-US" }
      }
      sort: { fields: [publishDate], order: DESC }
      limit: $limit,
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
          excerpt {
            excerpt
          }
          featuredImage {
            description
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    contentfulCategory(slug: { eq: $slug }) {
      title
      description
      seoTitle
      seoDescription {
        seoDescription
      }
      postHighlight {
        slug
        title
        featuredImage {
          description
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`;
