import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Thumbnail = styled(GatsbyImage)`
  border-radius: 6px;
  margin-bottom: 0.875rem;
  height: 15.3125rem;

  @media (min-width: 555px) {
    margin-bottom: 1.125rem;
  }
  @media (min-width: 990px) {
    margin-bottom: 1.25rem;
  }
`;

const Wrapper = styled.article`
  color: inherit;
  height: 100%;
  position: relative;
  padding: 0;

  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
  }

  &:hover&::after {
    content: '';
    width: calc(100% + (1.125rem * 2));
    left: -1.125rem;
    height: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
  }

  a {
    display: block;
    z-index: 2;
    position: relative;
  }

  @media (min-width: 555px) {
    padding: 1.375rem 0;
  }
`;

function PostCard({
  title, featuredImage, excerpt, slug
}) {
  return (
    <Wrapper>
      <Link to={`/blog/${slug}/`}>
        <header>
          {featuredImage && (
            <Thumbnail
              image={getImage(featuredImage)}
              alt={featuredImage.description}
              objectFit="cover"
              objectPosition="50% 50%"
            />
          )}
          <h3>{title}</h3>
        </header>
        {excerpt && (
          <section>
            <p>
              {excerpt.length >= 90
                ? `${excerpt.substring(0, 90)} [...]`
                : excerpt}
            </p>
          </section>
        )}
      </Link>
    </Wrapper>
  );
}

PostCard.defaultProps = {
  excerpt: '',
  featuredImage: {},
};

PostCard.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
  featuredImage: PropTypes.shape({
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      base64: PropTypes.string,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
    description: PropTypes.string,
  }),
};

export default PostCard;
